<div class="container">
  <div
    class="container__section container__section_test"
    *ngIf="!environment.production"
  >
    <lib-form-test-buttons
      (stepChange)="stepChange.emit($event)"
      (emailChange)="formEmail = { email: $event, email2: $event }"
      [address]="formShippingAddress"
      (addressChange)="formShippingAddress = $event"
      (addressAltChange)="
        formSameBillingAddress = false; formBillingAddress = $event
      "
      (paymentDataChange)="formPayment = $any($event)"
    ></lib-form-test-buttons>
  </div>
  <div
    class="container__section container__section_summary"
    *ngIf="summaryShow"
  >
    <app-section-summary>
      <app-section-summary-item
        *ngIf="summaryEmail"
        [key]="ESummary.email"
        [value]="summaryEmail"
        (change)="summaryOnChange($any($event))"
      ></app-section-summary-item>
      <app-section-summary-item
        *ngIf="summaryShippingAddress"
        [key]="ESummary.shippingAddress"
        [value]="summaryShippingAddress"
        (change)="summaryOnChange($any($event))"
      ></app-section-summary-item>
      <app-section-summary-item
        *ngIf="summaryBillingAddress"
        [key]="ESummary.billingAddress"
        [value]="summaryBillingAddress"
        (change)="summaryOnChange($any($event))"
      ></app-section-summary-item>
      <app-section-summary-item
        *ngIf="summaryShippingMethod"
        [key]="ESummary.shippingMethod"
        [value]="summaryShippingMethod"
        (change)="summaryOnChange($any($event))"
      ></app-section-summary-item>
    </app-section-summary>
  </div>
  <!-- email -->
  <div
    class="container__section"
    *ngIf="step === 1"
  >
    <lib-form-email
      #formEmailRef
      headerTitle="連絡先"
      headerSubtitle="ショップからの連絡（ご注文内容・発送など）をさせていただきます"
      [(data)]="formEmail"
      (dataChange)="onChange()"
    ></lib-form-email>
  </div>
  <!-- shipping address -->
  <div
    class="container__section"
    *ngIf="step === 1"
  >
    <lib-form-stripe-address
      #formShippingAddressRef
      headerTitle="お届け先情報"
      headerSubtitle="領収書など金額がわかるものは同封しません"
      [(data)]="formShippingAddress"
      (dataChange)="onChange()"
      footerTitle="※電話番号は最初の０からご記入ください"
    ></lib-form-stripe-address>
  </div>
  <!-- billing address -->

  <div
    class="container__section"
    *ngIf="step === 1"
  >
    <!-- todo app-form-header -->
    <lib-form-header-checkout
      title="請求先情報"
      subtitle="このご注文をお支払いされる方、発送伝票に【依頼人】として明記されます"
    >
      <div class="shipping-address">
        <app-toggle-same-address
          class="shipping-address__section"
          [(active)]="formSameBillingAddress"
          (activeChange)="onChange()"
        ></app-toggle-same-address>
        <lib-form-stripe-address
          class="shipping-address__section"
          #formBillingAddressRef
          *ngIf="!formSameBillingAddress"
          [(data)]="formBillingAddress"
          (dataChange)="onChange()"
        ></lib-form-stripe-address>
      </div>
    </lib-form-header-checkout>
  </div>
  <div
    class="container__section"
    *ngIf="step === 2"
  >
    <app-form-shipping-method
      [(shipping)]="shipping"
      [disabledShippings]="disabledShippings"
      [shippings]="shippings"
      (shippingChange)="onChange()"
      [region]="region"
    ></app-form-shipping-method>
  </div>
  <div
    class="container__section container__section_payment"
    *ngIf="step === 3"
  >
    <lib-form-payment
      [(data)]="formPayment"
      (dataChange)="onChange()"
      (submitButtonClick)="nextClick.emit()"
      [submitButtonDisabled]="isNextDisabled"
      [shipping]="shipping"
      [region]="region"
    ></lib-form-payment>
  </div>
  <div class="container__section">
    <lib-navigation
      [hideNext]="step === 3"
      [nextText]="
        step === 1
          ? '配送方法選択に進む'
          : step === 2
            ? 'お支払いに進む'
            : 'お支払いを完了する'
      "
      [isBackDisabled]="isBackDisabled"
      [isNextDisabled]="isNextDisabled"
      (backClick)="backClick.emit()"
      (nextClick)="nextClick.emit()"
    ></lib-navigation>
  </div>
</div>
