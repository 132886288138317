import {
  BOOK_ALIAS_TOKYO_METRO,
  BOOK_PRICE_TOKYO_METRO,
  BOOK_PRICE_TOKYO_METRO_HARD_COVER,
  INPUT_NAME_CHARACTER,
  INPUT_NAME_GENDER,
  INPUT_NAME_GLASSES,
  INPUT_NAME_KIDS_NAME,
  INPUT_NAME_KUN_CHAN,
  INPUT_NAME_TRAIN_LINE,
  INPUT_VALUE_COVER_TYPE_HARD,
  INPUT_VALUE_COVER_TYPE_SOFT,
  INPUT_VALUE_GENDER_BOY,
  INPUT_VALUE_GENDER_GIRL,
  INPUT_VALUE_KUN_CHAN_NASHI,
} from '@studiobuki/shared/dist/book/constants';
import { kunChanArr, trainLineArr } from '@studiobuki/shared/dist/book/data';
import {
  GENDER_TO_CHARACTER_SD_MAP,
  KUNCHAN_VALUE_TO_LABEL_MAP,
  TRAIN_LINE_VALUE_TO_LABEL_MAP_KANJI,
} from '@studiobuki/shared/dist/book/maps';
import { EBookBannerTextColor } from '@studiobuki/shared/dist/data/books/banner/types';
import { EBookDescriptionTextItemType } from '@studiobuki/shared/dist/data/books/desicription/types';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from '@studiobuki/shared/dist/data/books/inputs/select/utils';
import {
  getBookMultiselectInputData,
  getBookMultiselectInputOptionData,
} from '@studiobuki/shared/dist/data/books/inputs/multiselect/utils';
import { getBookTextInputData } from '@studiobuki/shared/dist/data/books/inputs/text/utils';
import { getBookNameValidatorList } from '@studiobuki/shared/dist/data/books/inputs/validators';
import { GET_BOOK_NAME_ERROR } from '@studiobuki/shared/dist/data/books/inputs/validators.constants';
import { getBookPriceData } from '@studiobuki/shared/dist/data/books/prices/utils';
import {
  PURPOSE_ADVENTURE,
  PURPOSE_BIRTH_GIFT,
} from '@studiobuki/shared/dist/data/books/properties/constants';
import type { IBookReview } from '@studiobuki/shared/dist/data/books/reviews/types';
import type { IBook } from '@studiobuki/shared/dist/data/books/types';
import type { TGender } from '@studiobuki/shared/dist/book/interfaces';
import {
  getBookRadioInputData,
  getBookRadioInputOptionData,
} from '@studiobuki/shared/dist/data/books/inputs/radio/utils';
import {
  getBookRadioImageInputData,
  getBookRadioImageInputOptionData,
} from '@studiobuki/shared/dist/data/books/inputs/radioImage/utils';
import { bookInputShowWhen } from '@studiobuki/shared/dist/data/books/inputs/utils';
import { getBookCheckboxInputData } from '@studiobuki/shared/dist/data/books/inputs/checkbox/utils';
import { getCoverHard, getCoverSoft } from './_utils';

export const TokyoMetroInputsKidsName = getBookTextInputData({
  label: '子どもの下の名前',
  placeholder: 'ひらがな６文字まで',
  name: INPUT_NAME_KIDS_NAME,
  validator: getBookNameValidatorList(6, 2),
  error: GET_BOOK_NAME_ERROR(6),
} as const);

export const TokyoMetroInputsKunChan = getBookSelectInputData({
  label: 'くん・ちゃん付け',
  placeholder: '選んでください',
  options: kunChanArr.map((value) =>
    getBookSelectInputOptionData({
      label: KUNCHAN_VALUE_TO_LABEL_MAP[value],
      value,
    }),
  ),
  value: INPUT_VALUE_KUN_CHAN_NASHI,
  name: INPUT_NAME_KUN_CHAN,
} as const);

export const TokyoMetroInputsTrainLine = getBookSelectInputData({
  label: '何線の運転士？',
  placeholder: '選んでください',
  options: trainLineArr.map((value) =>
    getBookSelectInputOptionData({
      label: TRAIN_LINE_VALUE_TO_LABEL_MAP_KANJI[value],
      value,
    }),
  ),
  // value: '',
  name: INPUT_NAME_TRAIN_LINE,
} as const);

export const TokyoMetroInputsTrainLineAlt = getBookMultiselectInputData({
  label: '何線の運転手？',
  options: trainLineArr.map((value) =>
    getBookMultiselectInputOptionData({
      label: TRAIN_LINE_VALUE_TO_LABEL_MAP_KANJI[value],
      value,
      imgSrc: `/assets/images/tm-lines/${value.toLowerCase()}.png`,
    }),
  ),
  name: INPUT_NAME_TRAIN_LINE,
  min: 1,
  max: 1,
});

export const TokyoMetroInputsGender = getBookRadioInputData({
  label: '性別',
  options: [
    getBookRadioInputOptionData({
      label: '男の子',
      // imgSrc: 'assets/images/radio-boy.png',
      value: INPUT_VALUE_GENDER_BOY,
    }),
    getBookRadioInputOptionData({
      label: '女の子',
      // imgSrc: 'assets/images/radio-girl.png',
      value: INPUT_VALUE_GENDER_GIRL,
    }),
  ],
  // value: INPUT_VALUE_GENDER_BOY,
  name: INPUT_NAME_GENDER,
});

export const TokyoMetroInputsCharacterBoy = getBookRadioImageInputData({
  label: '主人公にそっくりな子を選びましょう',
  options: GENDER_TO_CHARACTER_SD_MAP[INPUT_VALUE_GENDER_BOY].map(
    (value, index) =>
      getBookRadioImageInputOptionData({
        imgSrc: `assets/images/tm-characters/boy/${index + 1}.png`,
        value,
      }),
  ),
  // value: '',
  name: INPUT_NAME_CHARACTER,
} as const);

export const TokyoMetroInputsCharacterGirl = getBookRadioImageInputData({
  label: '主人公にそっくりな子を選びましょう',
  options: GENDER_TO_CHARACTER_SD_MAP[INPUT_VALUE_GENDER_GIRL].map(
    (value, index) =>
      getBookRadioImageInputOptionData({
        imgSrc: `assets/images/tm-characters/girl/${index + 1}.png`,
        value,
      }),
  ),
  // value: '',
  name: INPUT_NAME_CHARACTER,
} as const);

export const TokyoMetroInputsGlasses = getBookCheckboxInputData({
  label: 'メガネをかける',
  name: INPUT_NAME_GLASSES,
  value: false,
} as const);

// * mutations
bookInputShowWhen(
  TokyoMetroInputsCharacterBoy,
  TokyoMetroInputsGender,
  (gender: TGender) => gender === INPUT_VALUE_GENDER_BOY,
);

bookInputShowWhen(
  TokyoMetroInputsCharacterGirl,
  TokyoMetroInputsGender,
  (gender: TGender) => gender === INPUT_VALUE_GENDER_GIRL,
);

const prices = {
  [INPUT_VALUE_COVER_TYPE_SOFT]: getBookPriceData(BOOK_PRICE_TOKYO_METRO),
  [INPUT_VALUE_COVER_TYPE_HARD]: getBookPriceData(
    BOOK_PRICE_TOKYO_METRO_HARD_COVER,
  ),
} as const;

const coverSoft = getCoverSoft({
  price: prices[INPUT_VALUE_COVER_TYPE_SOFT],
  carousel: [
    'assets/images/covers/tokyometro/SC01.jpg',
    'assets/images/covers/tokyometro/SC02.jpg',
    'assets/images/covers/tokyometro/SC03.jpg',
  ],
});
const coverHard = getCoverHard({
  price: prices[INPUT_VALUE_COVER_TYPE_HARD],
  carousel: [
    'assets/images/covers/tokyometro/HC01.jpg',
    'assets/images/covers/tokyometro/HC02.jpg',
    'assets/images/covers/tokyometro/HC03.jpg',
  ],
});

const reviewMock: IBookReview = {
  photoImgSrc: 'https://placehold.co/349',
  userPhotoImgSrc: 'https://placehold.co/100',
  userName: '',
  text: [
    '息子のクリスマスプレゼントで購入🎄🎁',
    '息子が主人公で物語がはじまるとっても素敵な絵本💐',
    '絵も可愛くて息子も母もニコニコ︎︎︎︎︎😀出産祝いにもいいなって思った🕊',
  ],
};

const TokyoMetro: IBook = {
  caption: '電車の運転士になろう！',
  summary: [
    '僕・私は東京メトロの運転士！電車を運転ってどうやるの？きっと、電車がもっと好きになる！ ',
  ],
  alias: BOOK_ALIAS_TOKYO_METRO,
  prices: [
    [INPUT_VALUE_COVER_TYPE_SOFT, prices[INPUT_VALUE_COVER_TYPE_SOFT]],
    [INPUT_VALUE_COVER_TYPE_HARD, prices[INPUT_VALUE_COVER_TYPE_HARD]],
  ],
  photos: [
    'assets/images/carousel01.jpg',
    'assets/images/carousel02.jpg',
    'assets/images/carousel03.jpg',
    'assets/images/carousel04.jpg',
  ],
  menuPhoto: 'assets/images/m-sample.jpg',
  previewPhoto: 'assets/images/m-sample.jpg',
  // TODO
  properties: {
    purpose: [PURPOSE_ADVENTURE, PURPOSE_BIRTH_GIFT],
    age: {
      start: 0,
      end: 3,
    },
  },
  notice: {
    caption: '憧れの電車を運転する',
    description:
      '僕・私は東京メトロの運転士！電車を運転ってどうやる\nの？きっと、電車がもっと好きになる！',
    points: [
      {
        imgSrc: 'assets/images/welcome-notice-point-1.png',
        caption: '３〜８歳におすすめ',
      },
      {
        imgSrc: 'assets/images/welcome-notice-point-2.png',
        caption: '電車が好きな子へギフトに',
      },
    ],
  },
  points: [
    {
      caption: 'どんなカスタマイズができるの',
      description:
        '・子どもの名前を入れる<br>・子どものイラストを選ぶ<br>・運転する路線を選ぶ<br>・メッセージを書き込む<br>・写真を入れる',
    },
    {
      caption: '絵本のストーリーは？',
      description:
        '「よろしくお願いします」そう言われて気づいたら、あれれ、東京メトロの運転士になっていた！運転する路線は？車両は？運転ってどうやるの？運転士って何食べる？<br>絵本の中で運転士のお仕事を体験しちゃおう！',
    },
    {
      caption: 'ページ数や絵本の大きさは？',
      description:
        '全ての絵本は１冊ずつ、ご注文を受けてからオーダーメイドで印刷・製本されます。マットで高級感のある、環境に配慮した用紙を使用しています。<br><br><b>・全32 ページ<br>・縦21cm x 横29.7cm（本文部分）のA4ヨコサイズ<br>・日本国内で製造</b>',
    },
  ],
  inputs: [
    TokyoMetroInputsKidsName,
    TokyoMetroInputsKunChan,
    TokyoMetroInputsTrainLine,
    TokyoMetroInputsGender,
    TokyoMetroInputsCharacterBoy,
    TokyoMetroInputsCharacterGirl,
    TokyoMetroInputsGlasses,
  ],
  banner: {
    imgSrc: 'assets/images/bk3-banner-desktop-JP.jpg',
    imgSrcLtMd: 'assets/images/bk3-banner-mobile-JP.jpg',
    text: 'まいにちが　だいぼうけん。　ここにある　まほうを　みつけよう。',
    textColor: EBookBannerTextColor.dark,
  },
  description: [
    {
      imgSrc: 'assets/images/image01.gif',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">その子が運転士の電車絵本</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '電車好きな子にピッタリなプレゼント。選べる路線は東京メトロの９路線！銀座線、東西線・・好きな路線はどれ？路線ごとに変わるイラストにもご注目！',
        },
      ],
    },
    {
      imgSrc: 'assets/images/image02.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">電車をもっと好きになる！</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,

          text: `運転士って何食べてるの？いつも持ってるあの鞄は何？など、運転士のお仕事をはじめ、電車や路線ごとのミニ知識がたっくさん！さぁ、キミはどこまで知ってたかな？`,
        },
      ],
    },
    {
      imgSrc: 'assets/images/image03.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">メッセージを添えて</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '絵本の１ページ目にはオリジナルのメッセージが印刷されます。この絵本を読む子に向けて、「大好きだよ」「応援しているよ」など、大切な言葉を残しましょう。',
        },
      ],
    },
    {
      imgSrc: 'assets/images/image04.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">好きな路線を選べる</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '運転するのは、銀座線？丸ノ内線？それとも、東西線？東京メトロの９路線の中から、好きな路線を選べます。路線ごとに変わる細かな点に、気づけるかな？',
        },
      ],
    },
  ],
  review: {
    text: [
      '電車がとにかく好きな甥っ子へのプレゼントに選びました。すごく喜んでくれたので良かったです。',
    ],
  },
  reviews: [
    reviewMock,
    reviewMock,
    reviewMock,
    reviewMock,
    reviewMock,
    reviewMock,
  ],
  covers: {
    [INPUT_VALUE_COVER_TYPE_HARD]: coverHard,
    [INPUT_VALUE_COVER_TYPE_SOFT]: coverSoft,
  },
  cover: INPUT_VALUE_COVER_TYPE_SOFT,
};

export default TokyoMetro;
