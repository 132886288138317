import type { TBookAlias } from '@studiobuki/shared/dist/book/interfaces';
import memoize from 'lodash/memoize';
import Books from './index';

export const getBookByAlias = memoize((alias: TBookAlias) => {
  const book = Books.find((b) => b.alias === alias);

  if (!book)
    throw new Error(
      `book not found by alias "${alias}", probably use USER_PROJECT_BOOKS injection token`,
    );

  return book;
});
